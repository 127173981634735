<template>
  <div class="py-5 text-center">
    <h1>Picking List System</h1>
    <br />
    <b-img
      :src="logo"
      blank-color="#ccc"
      width="150"
      alt="dosetech-logo"
    ></b-img>
    <br />
    <br />
    <br />
    <div v-if="isLoading">
      <b-spinner variant="warning" type="grow" label="Loading..."></b-spinner>
      <b-spinner
        variant="warning"
        type="grow"
        label="Loading..."
        class="mx-2"
      ></b-spinner>
      <b-spinner variant="warning" type="grow" label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <p v-if="isLoginSuccess" class="success">Login Success</p>
      <div v-else>
        <b-button variant="shopee" @click="loginAuth" :disabled="isLoading">
          Connect Shopee
        </b-button>
        <b-button
          variant="mirakl"
          class="ml-2"
          @click="loginMirakl"
          :disabled="isLoading"
        >
          Connect Mirakl
        </b-button>
        <p class="text-danger mt-3" v-if="isCallback">Error : {{ msg }}</p>
      </div>
    </div>
  </div>
</template>

<script>
var sha256 = require("js-sha256");
import logo from "@/assets/dosetech-logo.png";

export default {
  data() {
    return {
      logo: logo,
      form: {
        timest: 0,
        host: this.$hostShopee,
        path: this.$path,
        redirect_url: this.$redirectUrl,
        partner_id: this.$partnerId,
        partner_key: this.$partnerKey,
        base_string: "",
        sign: "",
        url: "",
      },
      isLoading: false,
      msg: "",
      dataCallback: {
        code: "",
        shop_id: "",
      },
      isCallback: false,
      isLoginSuccess: false,
    };
  },
  methods: {
    loginAuth: async function () {
      this.msg = "";
      this.form.timest = parseInt(new Date().getTime() / 1000);
      this.form.base_string = `${this.form.partner_id}${this.form.path}${this.form.timest}`;
      var hash = sha256.hmac.create(this.form.partner_key);
      hash.update(this.form.base_string);
      this.form.sign = hash.hex();
      this.form.url = await `${this.form.host}${this.form.path}?partner_id=${this.form.partner_id}&redirect=${this.form.redirect_url}&timestamp=${this.form.timest}&sign=${this.form.sign}`;

      this.isLoading = true;
      window.location.href = this.form.url;
    },
    loginMirakl: async function () {
      this.$router.push("/LoginMirakl");
    },
    async getToken(code, shop_id) {
      await this.axios
        .post(`${this.$baseUrl}/shopee/token`, {
          code,
          shop_id: parseInt(shop_id),
        })
        .then(async (res) => {
          if (res.result == 1) {
            this.isLoginSuccess = true;
            await localStorage.setItem(
              "dt_shopee_access_token",
              `${res.detail.access_token}`
            );
            this.$router.push({
              name: "callback-msg",
              params: { msg: "success" },
            });
          } else {
            this.msg = res.message;
          }
        })
        .catch((err) => {
          this.msg = err.error_message;
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    if (this.$route.name == "callback") {
      this.isCallback = true;
      this.dataCallback.code = this.$route.query.code;
      this.dataCallback.shop_id = this.$route.query.shop_id;

      this.isLoading = true;
      this.getToken(this.dataCallback.code, this.dataCallback.shop_id);
    } else if (this.$route.name == "callback-msg") {
      this.isCallback = true;
      this.isLoginSuccess = true;
      this.isLoading = false;
    } else {
      // localStorage.removeItem("dt_shopee_access_token");
    }
  },
};
</script>

<style scoped>
.btn-shopee {
  color: #fff;
  background: #ee4d2d;
  padding: 8px 30px;
}
.btn-mirakl {
  color: #fff;
  background: #1d426a;
  padding: 8px 30px;
}
.success {
  color: #28a745 !important;
}
</style>